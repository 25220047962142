import './style_v2.css'
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import collapse from '@alpinejs/collapse';

Alpine.plugin(intersect);
Alpine.plugin(collapse);

Alpine.data('anchorScroll', () => ({
  headerHeight:0,

  init(){
    const header = document.getElementsByTagName('header')[0];
    this.headerHeight = header ? header.offsetHeight : 0;
    if (window.location.hash) {
      const initialTarget = document.querySelector(CSS.escape(window.location.hash));
      if (initialTarget) {
        setTimeout(() => {
          initialTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
      }
    }
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', (e) => {
        e.preventDefault();
        const targetAnchor = e.target.closest('a');
        if (targetAnchor) {
          let targetId = targetAnchor.getAttribute('href');
          if (targetId.startsWith('#')) {
            targetId = targetId.slice(1);
          }
          const anchorClickTarget = document.querySelector(`#${CSS.escape(targetId)}`);
          if (anchorClickTarget) {
            window.location.hash = targetId;
            window.scrollTo({
              top: anchorClickTarget.offsetTop - header.offsetHeight - 25,
              behavior: 'smooth'
            });
          } else {
            console.log('Target not found:', targetId);
          }
        }
      });
    });
  },
}));

//setTimeout(() => { document.querySelectorAll('header')[0].classList.add('header-up') }, 1000);

Alpine.data('getNews', () =>({
  expanded: false,
  ajaxurl: ajax_object.ajaxurl,
	isLoading:true,
  posts: [],
  link:{},
  currentPage: 1,
  totalPages: 0,
  async fetchNews(categorySlug, page=1){
    const response = await fetch(`${this.ajaxurl}?action=lvp_fetch_posts_by_category&category=${categorySlug}&page=${page}`);
    const data = await response.json();
    this.posts = data.posts;
    this.totalPages = data.total_pages;
    this.link = data.link;
    this.currentPage = page;
		this.isLoading = false;
  },
	generatePagination(currentPage, totalPages) {
	  const buttons = [];
	  const maxButtons = 5;
	  let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
	  let endPage = Math.min(totalPages, startPage + maxButtons - 1);
	  if (endPage - startPage + 1 < maxButtons) {
	      startPage = Math.max(1, endPage - maxButtons + 1);
	  }
	  for (let i = startPage; i <= endPage; i++) {
	      buttons.push(i);
	  }
	  return buttons;
	}
}));

Alpine.data('getVideos', () =>({
  expanded: false,
  ajaxurl: ajax_object.ajaxurl,
	isLoading:false,
  videos: [],
  currentPage: 1,
  totalPages: 0,
  async fetchVideos(video_type, page=1){
    const response = await fetch(`${this.ajaxurl}?action=lvp_fetch_videos&video=${video_type}&page=${page}`);
    const data = await response.json();
    this.videos = data.videos;
    this.totalPages = data.total_pages;
    this.currentPage = page;
		this.isLoading = false;
  },
	generatePagination(currentPage, totalPages, t) {
	  const buttons = [];
	  const maxButtons = 5;
	  let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
	  let endPage = Math.min(totalPages, startPage + maxButtons - 1);
	  if (endPage - startPage + 1 < maxButtons) {
	      startPage = Math.max(1, endPage - maxButtons + 1);
	  }
	  for (let i = startPage; i <= endPage; i++) {
	      buttons.push(i);
	  }
	  return buttons;
	}
}));

window.Alpine = Alpine;

document.addEventListener('DOMContentLoaded', async () => {
  Alpine.start();
});